import { SvgIconProps } from '@/types/helpers'

export default function Upload(props: SvgIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M17.7072 9.2905C18.0977 9.68103 18.0977 10.3142 17.7072 10.7047C17.3167 11.0952 16.6835 11.0952 16.293 10.7047L13.502 7.91377V15C13.502 15.5523 13.0543 16 12.502 16C11.9498 16 11.502 15.5523 11.502 15V7.91368L8.70906 10.7067C8.31854 11.0972 7.68537 11.0972 7.29485 10.7067C6.90432 10.3161 6.90432 9.68298 7.29485 9.29245L11.7949 4.79241C11.9824 4.60487 12.2368 4.49951 12.502 4.49951C12.7672 4.49951 13.0216 4.60487 13.2091 4.79241L17.7072 9.2905Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 14C5.05228 14 5.5 14.4477 5.5 15V17C5.5 17.5523 5.94772 18 6.5 18H18.5C19.0523 18 19.5 17.5523 19.5 17V15C19.5 14.4477 19.9477 14 20.5 14C21.0523 14 21.5 14.4477 21.5 15V17C21.5 18.6569 20.1569 20 18.5 20H6.5C4.84315 20 3.5 18.6569 3.5 17V15C3.5 14.4477 3.94772 14 4.5 14Z"
        fill="currentFill"
      />
    </svg>
  )
}
