'use client'

import Image from 'next/image'
import FlowContent from './_components/FlowContent'
import bg1_3xl_2xl from './bg1_3xl_2xl.svg'
import bg1_md_sm from './bg1_md_sm.svg'
import bg1_xl_lg from './bg1_xl_lg.svg'

export default function Flow() {
  return (
    <div className="relative 2xl:z-[2] 2xl:pb-38 2xl:pt-10">
      <div className="relative contents lg:hidden">
        <Image
          src={bg1_md_sm}
          fill
          sizes="100vw"
          alt="background"
          className="-z-10 bg-butterscotch object-cover"
        />
      </div>
      <div className="relative hidden lg:contents 2xl:hidden">
        <Image
          src={bg1_xl_lg}
          fill
          sizes="100vw"
          alt="background"
          className="-z-10 bg-butterscotch object-cover"
        />
      </div>
      <div className="relative hidden 2xl:contents">
        <Image
          src={bg1_3xl_2xl}
          fill
          sizes="100vw"
          alt="background"
          className="-z-10 bg-butterscotch object-cover"
        />
      </div>

      <FlowContent />
    </div>
  )
}
