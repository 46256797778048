import { SvgIconProps } from '@/types/helpers'

export default function ArrowForwardBig(props: SvgIconProps) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="#3366B6"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M22.6426 31.041C21.9732 31.7179 21.9732 32.8154 22.6426 33.4923C23.3119 34.1692 24.3972 34.1692 25.0666 33.4923L34.9959 23.4513C36.3347 22.0975 36.3347 19.9025 34.9959 18.5487L25.0666 8.50768C24.3972 7.83077 23.3119 7.83077 22.6426 8.50768C21.9732 9.18459 21.9732 10.2821 22.6426 10.959L30.8578 19.2667H6.71405C5.76741 19.2667 5 20.0427 5 21C5 21.9573 5.76741 22.7333 6.71405 22.7333H30.8578L22.6426 31.041Z"
        fill="currentFill"
      />
    </svg>
  )
}
