'use client'

import Star from '@/app/_components/Icon/Star'

import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { twMerge } from 'tailwind-merge'

export default function Accordion({
  content,
  title,
  question_id,
  isOpen,
}: {
  question_id: number
  content: string
  title: string
  isOpen: boolean
}) {
  const [open, setOpen] = useState(false)

  const lines = content.split('\n').slice(1)
  const trimmedResponse = lines.join('\n')

  useEffect(() => {
    if (isOpen) {
      const questionElement = document.getElementById(`${question_id}`)
      if (questionElement) {
        if (!open) {
          questionElement.click()
        }
        questionElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [isOpen, question_id, open])

  return (
    <div className="mb-10 flex flex-col gap-12 rounded-xl bg-white p-24">
      <button
        id={`${question_id}`}
        type="button"
        className="flex max-h-75 items-center gap-16 outline-none"
        onClick={() => {
          setOpen(!open)
        }}>
        <div
          className={twMerge(
            open ? `rotate-[-45deg]` : ``,
            `duration-100 ease-linear`,
          )}>
          <Star width={28} height={27} fill="#FDCC53" />
        </div>

        <span className="c-h6 text-left font-bold text-black max-lg:c-h7">
          {title}
        </span>
      </button>
      {open && (
        <div className="faq-list__item c-b2 pl-45 text-gray max-lg:c-b4">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}>
            {trimmedResponse}
          </ReactMarkdown>
        </div>
      )}
    </div>
  )
}
