'use client'

import { CharacterItem } from '@/backend/models/Character'
import { useI18n } from '@/locales/client'
import { CharacterGrid } from './HotGrid'

export default function GeneralGrid({
  link,
  count,
  characters,
  extra,
}: {
  link: string
  count: number
  extra?: React.ReactNode
  characters: CharacterItem[]
}) {
  const t = useI18n()
  const currentTitle = t('common.mainCategoryLabel2')
  return (
    <CharacterGrid
      title={currentTitle}
      link={link}
      count={count}
      characters={characters}
      extra={extra}
    />
  )
}
