'use client'

import { useCurrentLocale, useI18n } from '@/locales/client'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

export default function AskSection() {
  const t = useI18n()
  const currentLang = useCurrentLocale()
  return (
    <div
      className={twMerge(
        'c-b3 text-[#80A8E7] lg:c-b2 z-20',
        currentLang === 'ja' ? '!text-[15px]' : '',
      )}>
      {t('common.mainAskCreator')}{' '}
      <Link href="/characters/create">
        <span className="text-butterscotch underline">
          {t('common.askRegCharacter')}
        </span>
      </Link>
    </div>
  )
}
