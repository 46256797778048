'use client'

import ArrowForwardBig from '@/app/_components/Icon/ArrowForwardBig'
import { useCurrentLocale, useI18n } from '@/locales/client'
import { twMerge } from 'tailwind-merge'

export default function FlowContent() {
  const t = useI18n()
  const currentLang = useCurrentLocale()
  return (
    <div className="container">
      <div className="mx-auto flex max-w-800 flex-col gap-40 pb-105 pt-94 lg:gap-20 xl:pb-212 xl:pt-223">
        <span
          className={twMerge(
            'b-h3 text-center text-french-bleu lg:b-h2',
            currentLang === 'ja' ? '!b-h4 lg:!b-h3' : '',
          )}>
          {t('common.mainSec2Title1')}&nbsp;
          <div className="lg:hidden" />
          {t('common.mainSec2Title2')}
        </span>
        <div className="flex flex-col items-center justify-between gap-10 lg:flex-row">
          <div className="c-h6 flex h-72 w-180 items-center justify-center rounded-100 bg-white text-french-bleu">
            {t('common.searchLabel')}
          </div>
          <ArrowForwardBig fill="#3366B6" className="max-lg:rotate-90" />
          <div className="c-h6 flex h-72 w-180 items-center justify-center rounded-100 bg-white text-french-bleu">
            {t('common.clickLabel')}
          </div>
          <ArrowForwardBig fill="#3366B6" className="max-lg:rotate-90" />
          <div className="c-h6 flex h-72 w-180 items-center justify-center rounded-100 bg-white text-french-bleu">
            {t('common.connectLabel')}
          </div>
        </div>
      </div>
    </div>
  )
}
