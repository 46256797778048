'use client'

import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

export default function Category({
  id,
  name,
  isActive,
  backgroundColor,
}: {
  id: number
  name: string
  isActive: boolean
  backgroundColor: `french-bleu` | `butterscotch`
}) {
  return (
    <Link
      prefetch={true}
      href={`?faqcategoryId=${id}`}
      scroll={false}
      className={twMerge(
        `flex h-68 items-center justify-center rounded-[100px]`,
        isActive
          ? 'border-1 border-solid p-24 max-lg:px-16 max-lg:py-12'
          : 'py-24 text-center max-lg:px-16 max-lg:py-12',
        backgroundColor === 'french-bleu' && `border-white`,
      )}>
      <p
        className={twMerge(
          `font-noto-sans-kr text-[20px] font-medium leading-[20px] max-lg:c-b3`,
          backgroundColor === 'french-bleu' && `text-white`,
        )}>
        {name}
      </p>
    </Link>
  )
}
