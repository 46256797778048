import { SvgIconProps } from '@/types/helpers'

export default function Star(props: SvgIconProps) {
  return (
    <svg
      width="27"
      height="29"
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_602_1689)">
        <path
          d="M13.478 0.890869L13.5246 0.890869C13.9227 8.41974 19.74 14.4525 27 14.8681L27 14.9163C19.74 15.3319 13.9227 21.362 13.522 28.8909L13.4754 28.8909C13.0747 21.362 7.25998 15.3319 -6.10963e-07 14.9137L-6.13073e-07 14.8654C7.25998 14.4525 13.0747 8.41974 13.478 0.890869Z"
          fill="currentFill"
        />
      </g>
      <defs>
        <clipPath id="clip0_602_1689">
          <rect
            width="28"
            height="27"
            fill="white"
            transform="translate(0 28.8909) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
