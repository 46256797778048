'use client'

import CharacterCard from '@/app/_components/Shared/CharacterCard'
import { SubHeader } from '@/app/_primitives/SubHeader'
import { CharacterItem } from '@/backend/models/Character'
import { useI18n } from '@/locales/client'

export function CharacterGrid({
  title,
  link,
  count,
  characters,
  extra,
}: {
  extra?: React.ReactNode
  title: string
  link: string
  count: number
  characters: CharacterItem[]
}) {
  return (
    <div className="flex flex-col gap-24">
      <SubHeader count={`${count}`} href={link}>
        {title}
      </SubHeader>
      <div className="flex gap-24 max-lg:flex-col max-lg:items-center lg:flex-wrap">
        {characters.map((c, idx) => (
          <CharacterCard
            character={c}
            key={c.id}
            className={idx === 2 ? 'xl:hidden 2xl:inline' : ''}
          />
        ))}
        {extra}
      </div>
    </div>
  )
}

export default function HotGrid({
  link,
  count,
  characters,
  extra,
}: {
  extra?: React.ReactNode
  link: string
  count: number
  characters: CharacterItem[]
}) {
  const t = useI18n()
  const currentTitle = t('common.mainCategoryLabel1')
  return (
    <CharacterGrid
      title={currentTitle}
      link={link}
      count={count}
      characters={characters}
      extra={extra}
    />
  )
}
