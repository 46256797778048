'use client'

import Upload from '@/app/_components/Icon/Upload'
import { useCurrentLocale, useI18n } from '@/locales/client'
import Image from 'next/image'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import img from './img.png'

export default function Register() {
  const t = useI18n()
  const currentLang = useCurrentLocale()
  return (
    <div className="relative bg-butterscotch">
      <div className="container">
        <div className="flex flex-col items-center justify-center gap-118 py-126 lg:gap-74 lg:pb-107 lg:pt-74 2xl:flex-row">
          <div className="relative aspect-[400/276] w-full lg:w-606">
            <Image src={img} fill alt="img" />
          </div>

          <div className="flex flex-col gap-16 lg:gap-20 xl:w-478">
            <span
              className={twMerge(
                'b-h3 whitespace-pre-line text-french-bleu lg:b-h2',
                currentLang === 'ja' ? '!b-h4 lg:!b-h3' : '',
              )}>
              {`${t('common.mainSec5Title1')}\n${t(
                'common.mainSec5Title2',
              )}\n${t('common.mainSec5Title3')}`}
            </span>

            <Link
              href="/characters/create"
              className="flex h-58 w-320 items-center justify-center gap-7 rounded-100 bg-french-bleu">
              <Upload />
              <span
                className={twMerge(
                  'c-h6 text-white',
                  currentLang === 'ja' ? '!c-h7' : '',
                )}>
                {t('common.mainSec5BtnLabel')}
              </span>
            </Link>

            <span className="c-b3 text-[#4B5157]">
              {t('common.mainSec5Desc')}: JPG, PNG | 76mb{' '}
              {t('common.belowCountLabel')}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
